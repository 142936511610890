import { toAbsoluteUrl } from '../../../utils/AssetsHelpers';

export const STATUS_DATA = {
  confirmed: {
    translationKey: 'payments.statusDescriptions.completed',
    color: '#8B54F7',
    iconSrc: toAbsoluteUrl('/assets/icons/status/completed.svg'),
  },
  pending: {
    translationKey: 'payments.statusDescriptions.pending',
    color: '#F7AA4E',
    iconSrc: toAbsoluteUrl('/assets/icons/status/pending.svg'),
  },
  cancel: {
    translationKey: 'payments.statusDescriptions.rejected',
    color: '#F75454',
    iconSrc: toAbsoluteUrl('/assets/icons/status/reject.svg'),
  },
};
