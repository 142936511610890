import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { AuthRoute, PrivateRoute, CircularProgress } from './components';
import { PATHS } from '../constants/paths';
import { useLoadGoogleApis } from './hooks/useLoadGoogleApis';
import { useGeolocation } from './hooks/useGeolocation';

// const HomePage = lazy(() => import('./modules/Home'));
const LoginPage = lazy(() => import('./modules/Auth/pages/Login'));
const RegistrationPage = lazy(() =>
  import('./modules/Auth/pages/Registration'),
);
const SharedAuthPage = lazy(() => import('./modules/Auth/pages/SharedAuth'));
const UsersPage = lazy(() => import('./modules/Users'));
const CashInPage = lazy(() => import('./modules/CashIn'));
const CashOutPage = lazy(() => import('./modules/CashOut'));
const PaymentsPage = lazy(() => import('./modules/Payments'));
const WalletPage = lazy(() => import('./modules/Wallet'));
// const LiquidationsPage = lazy(() => import('./modules/Liquidations'));
// const LiquidationRequestPage = lazy(() =>
//   import('./modules/LiquidationRequest'),
// );
const MovementsPage = lazy(() => import('./modules/Movements'));
const SettingsPage = lazy(() => import('./modules/Settings'));
const ProfilePage = lazy(() => import('./modules/Profile'));
const ReportsPage = lazy(() => import('./modules/Reports'));
const ReportsHistoryPage = lazy(() => import('./modules/ReportsHistory'));
const AboutUsPage = lazy(() => import('./modules/AboutUs'));
const ContactPage = lazy(() => import('./modules/Contact'));
const TermsAndConditionsPage = lazy(() =>
  import('./modules/TermsAndConditions'),
);
const PrivacyPolicyPage = lazy(() => import('./modules/PrivacyPolicy'));
const FrequentQuestionsPage = lazy(() => import('./modules/FrequentQuestions'));
const ConfirmationPage = lazy(() => import('./modules/Confirm'));
const MyStore = lazy(() => import('./modules/Store'));
const MyStoreTokens = lazy(() => import('./modules/StoreTokens'));
const MyStoreLocales = lazy(() => import('./modules/StoreLocales'));
const MyStoreLocalNew = lazy(() => import('./modules/StoreLocaleNew'));
const MyStoreLocalUpdate = lazy(() => import('./modules/StoreLocaleUpdate'));
const Recovery = lazy(() => import('./modules/Recovery'));
const ChangePasswor = lazy(() => import('./modules/ChangePassword'));

const NotFound = () => <h1>No found</h1>;

const App = () => {
  useLoadGoogleApis();
  useGeolocation();

  return (
    <Router basename="/comercios">
      <Suspense fallback={<CircularProgress style={{ height: '100vh' }} />}>
        <Switch>
          <PrivateRoute exact path={PATHS.home.path} component={WalletPage} />
          <AuthRoute exact path={PATHS.login.path} component={LoginPage} />
          <Route path={PATHS.sharedAuth.path} component={SharedAuthPage} />
          <AuthRoute
            exact
            path={PATHS.registration.path}
            component={RegistrationPage}
          />
          <PrivateRoute path={PATHS.users.path} component={UsersPage} />
          <PrivateRoute path={PATHS.cashIn.path} component={CashInPage} />
          <PrivateRoute path={PATHS.cashOut.path} component={CashOutPage} />
          <PrivateRoute path={PATHS.payments.path} component={PaymentsPage} />
          {/* <PrivateRoute path={PATHS.wallet.path} component={WalletPage} exact /> */}
          {/* <PrivateRoute
            path={PATHS.liquidations.path}
            component={LiquidationsPage}
            exact
          /> */}
          {/* <PrivateRoute
            path={PATHS.liquidationRequest.path}
            component={LiquidationRequestPage}
            exact
          /> */}
          <PrivateRoute
            path={PATHS.movements.path}
            component={MovementsPage}
            exact
          />
          <PrivateRoute path={PATHS.myStore.path} component={MyStore} exact />
          <PrivateRoute
            path={PATHS.myStoreTokens.path}
            component={MyStoreTokens}
            exact
          />
          <PrivateRoute
            path={PATHS.myStoreLocales.path}
            component={MyStoreLocales}
            exact
          />
          <PrivateRoute
            path={PATHS.myStoreLocalNew.path}
            component={MyStoreLocalNew}
            exact
          />
          <PrivateRoute
            path={PATHS.myStoreLocalEdit.path}
            component={MyStoreLocalUpdate}
            exact
          />
          <PrivateRoute path={PATHS.settings.path} component={SettingsPage} />
          <PrivateRoute path={PATHS.profile.path} component={ProfilePage} />
          <PrivateRoute
            path={PATHS.reports.path}
            component={ReportsPage}
            exact
          />
          <PrivateRoute
            path={PATHS.reportsHistory.path}
            component={ReportsHistoryPage}
            exact
          />

          <Route path={PATHS.aboutUs.path} component={AboutUsPage} />
          <Route path={PATHS.contact.path} component={ContactPage} />
          <Route
            path={PATHS.termsAndConditions.path}
            component={TermsAndConditionsPage}
          />
          <Route
            path={PATHS.privacyPolicy.path}
            component={PrivacyPolicyPage}
          />
          <Route
            path={PATHS.frequentQuestions.path}
            component={FrequentQuestionsPage}
          />
          <Route path={PATHS.confirmation.path} component={ConfirmationPage} />
          <Route path={PATHS.passwordRecovery.path} component={Recovery} />
          <Route path={PATHS.ChangePasswor.path} component={ChangePasswor} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
