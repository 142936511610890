export default (theme) => ({
  card: {
    background: '#FFF',
    boxShadow: 'none',
    borderRadius: 20,

    '& .MuiCardContent-root': {
      padding: '34px 18.5px',
      gap: 23,
      height: 601,

      [theme.breakpoints.up(768)]: {
        padding: '34px 45px',
        height: 349,
      },

      '&:last-child': {
        paddingBottom: '34px',
      },
    },
  },
  title: {
    color: '#49454F',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '27px',
    letterSpacing: '0.5px',

    [theme.breakpoints.up(768)]: {
      fontSize: 18,
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 24,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 23,
    alignItems: 'center',

    [theme.breakpoints.up(768)]: {
      flexDirection: 'row-reverse',
      gap: 70,
    },

    [theme.breakpoints.up(1024)]: {
      gap: 53,
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 23,
    alignItems: 'center',

    [theme.breakpoints.up(768)]: {
      flex: 1,
      alignItems: 'flex-start',
      height: '100%',
    },
  },
  text: {
    color: '#49454F',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.5px',

    [theme.breakpoints.up(1024)]: {
      fontSize: 18,
    },

    '& strong': {
      fontWeight: 600,
    },
  },
  button: {
    maxWidth: 200,
    marginTop: 'auto',

    '& .MuiButton-root': {
      borderRadius: 16,
      color: '#FFF',
      textAlign: 'center',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
      minHeight: 48,
    },
  },
});
