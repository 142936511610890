import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { PATHS } from '../../../constants/paths';
import CardWithProgressLoading from '../CardWithLoadingProgress';
import { toAbsoluteUrl } from '../../../utils/AssetsHelpers';

import PaymentsListItem from './Item';
import styles from './styles';

const useStyles = makeStyles(styles);

const PaymentsList = ({ loading, data }) => {
  const { t } = useTranslation('common');

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {t('payments.paymentHistory')}
      </Typography>

      <CardWithProgressLoading
        loading={loading}
        className={classes.card}
        progressSize={65}
        fullHeight={false}
      >
        {data.length === 0 && (
          <Box className={classes.emptyContainer}>
            <Box
              component="img"
              src={toAbsoluteUrl('/assets/icons/emptyState2.svg')}
              alt="Empty State Icon"
              className={classes.emptyIcon}
            />

            <Typography className={classes.emptyText}>
              {t('payments.noResults')}
            </Typography>
          </Box>
        )}

        {data.length > 0 && (
          <>
            <Box className={classes.list}>
              {data.map((item) => (
                <PaymentsListItem
                  key={`payment-${item.id}`}
                  createdAt={item.created_at}
                  amount={item.total_amount}
                  currency={item.fiat}
                  state={item.summary_state}
                />
              ))}
            </Box>

            <RouterLink
              to={PATHS.payments.path}
              className={classes.showMoreLink}
            >
              {t('general.viewMore')}
              <span>►</span>
            </RouterLink>
          </>
        )}
      </CardWithProgressLoading>
    </Box>
  );
};

PaymentsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      currency: PropTypes.string,
      description: PropTypes.string,
      gateway: PropTypes.string,
      id: PropTypes.number,
      // eslint-disable-next-line react/forbid-prop-types
      metadata: PropTypes.object,
      payed_at: PropTypes.string,
      state: PropTypes.string,
      total_amount: PropTypes.number,
      crypto_currency: PropTypes.string,
      summary_state: PropTypes.string,
      fiat: PropTypes.string,
    }),
  ).isRequired,
};

export default PaymentsList;
