import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '../../hooks';
import { sendEmailAdmins } from '../../../services/merchantsService';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import styles from './styles';

const useStyles = makeStyles(styles);

const StoreQrWidget = ({ loading, url }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [, loadingSendEmail, , executeSendEmail] = useLazyRequest({
    request: sendEmailAdmins,
    withPostSuccess: () => {
      enqueueSnackbar(
        ERRORS_MESSAGE.actionRequestSuccess('Su solicitud ha sido creada'),
        { variant: 'success' },
      );
    },
    withPostFailure: () => {
      enqueueSnackbar(
        ERRORS_MESSAGE.actionRequestError('registrar su solicitud'),
        { variant: 'error' },
      );
    },
  });

  return (
    <CardWithProgressLoading
      loading={loading}
      className={classes.card}
      progressSize={65}
      fullHeight={false}
    >
      <Typography variant="h6" className={classes.title}>
        {t('store.paymentQR')}
      </Typography>

      <Box className={classes.content}>
        <QRCode value={url} size={231} />

        <Box className={classes.info}>
          <Typography className={classes.text}>
            <Trans
              t={t}
              i18nKey="store.paymentQRDescription1"
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>

          <Typography className={classes.text}>
            <Trans
              t={t}
              i18nKey="store.paymentQRDescription2"
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>

          <ButtonWithCirculaProgress
            color="secondary"
            fullWidth
            loading={loadingSendEmail}
            label={t(`store.requestQR`)}
            handleButtonClick={executeSendEmail}
            className={classes.button}
          />
        </Box>
      </Box>
    </CardWithProgressLoading>
  );
};

StoreQrWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

StoreQrWidget.defaultProps = {
  url: '',
};

export default StoreQrWidget;
