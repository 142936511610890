import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import MobileMenuLink from './Link';

import styles from './styles';

const useStyles = makeStyles(styles);

const MobileMenuCollapse = ({ label, data, handleClose }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const contentRef = useRef(null);
  const [heightContent, setHeightContent] = useState(0);

  useEffect(() => {
    if (open)
      setHeightContent(contentRef.current?.getBoundingClientRect().height);
    else setHeightContent(0);
  }, [open]);

  return (
    <div className={classes.collapseRoot}>
      <button
        type="button"
        onClick={handleToggleOpen}
        className={classnames(classes.collpaseBtn, {
          [classes.collapseOpen]: open,
        })}
      >
        <span>{label}</span>

        <span className={classes.collapseBtnArrow}>▸</span>
      </button>

      <div
        className={classnames(classes.collapseContent, {
          [classes.collapseOpen]: open,
        })}
        style={{ height: heightContent }}
      >
        <div ref={contentRef}>
          {data.map(({ title, path }) => (
            <div
              key={`menu-mobile-tabs-panel-${label}list-collapse-${title}`}
              className={classnames(
                classes.tabPanelListItem,
                classes.tabPanelListItemNoBorderLastItem,
              )}
            >
              <MobileMenuLink
                key={`menu-mobile-tabs-panel-${label}list-collapse-${title}`}
                title={title}
                path={path}
                handleClose={handleClose}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MobileMenuCollapse.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MobileMenuCollapse;
