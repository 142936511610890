export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 28,
  },
  title: {
    color: '#49454F',
    textAlign: 'center',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',

    [theme.breakpoints.up(768)]: {
      fontSize: 24,
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 40,
    },
  },
  card: {
    boxShadow: 'none',
    borderRadius: 20,
    background: '#FFF',

    '& .MuiCardContent-root': {
      minHeight: 276,
      padding: 20,
      gap: 16,

      [theme.breakpoints.up(768)]: {
        minHeight: 291,
        gap: 10,
      },

      [theme.breakpoints.up(1024)]: {
        minHeight: 292,
      },

      '&:last-child': {
        paddingBottom: 20,
      },
    },
  },
  emptyContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    flex: 1,
    justifyContent: 'center',

    [theme.breakpoints.up(768)]: {
      gap: 24,
    },
  },
  emptyIcon: {
    height: 41,
    width: 30,

    [theme.breakpoints.up(768)]: {
      height: 80,
      width: 59,
    },
  },
  emptyText: {
    fontSize: 16,
    fontWeight: 400,

    [theme.breakpoints.up(768)]: {
      fontSize: 24,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    [theme.breakpoints.up(768)]: {
      gap: 24,
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 24.04,

    [theme.breakpoints.up(1024)]: {
      gap: 30.96,
    },
  },
  listItemRootIconContainer: {
    display: 'flex',
    width: 46.047,
    height: 53,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8.987,
    background: '#FEEBD9',

    [theme.breakpoints.up(768)]: {
      width: 104.96655,
      height: 90,
    },

    [theme.breakpoints.up(1024)]: {
      width: 132.67346,
    },
  },
  listItemRootIconImg: {
    width: 12.015,
    height: 24.807,
    flexShrink: 0,

    [theme.breakpoints.up(768)]: {
      width: 30.036,
      height: 62.016,
    },
  },
  listItemContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  listItemContentColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItemContentColumnAlignEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  listItemContentLabel: {
    color: '#273240',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '47.875px',
    letterSpacing: '0.624px',

    [theme.breakpoints.up(768)]: {
      fontSize: 24,
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 28,
    },
  },
  listItemContentLabelSmall: {
    color: '#273240',
    textAlign: 'right',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '47.875px',
    letterSpacing: '0.624px',
    textTransform: 'uppercase',

    [theme.breakpoints.up(768)]: {
      fontSize: 24,
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 28,
    },
  },
  listItemContentDescription: {
    color: '#2C3A4B',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '41.63px',
    letterSpacing: '0.624px',
    opacity: 0.5,

    [theme.breakpoints.up(768)]: {
      fontSize: 24,
    },
  },
  listItemContentStatusContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',

    [theme.breakpoints.up(768)]: {
      gap: 16,
    },
  },
  listItemContentStatusIcon: {
    width: 14.4,
    height: 14.4,
    flexShrink: 0,

    [theme.breakpoints.up(768)]: {
      width: 21.6,
      height: 21.6,
    },
  },
  listItemContentStatusText: {
    textAlign: 'right',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '41.63px',
    letterSpacing: '0.624px',

    [theme.breakpoints.up(768)]: {
      fontSize: 20,
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 28,
    },
  },
  showMoreLink: {
    width: 'max-content',
    alignSelf: 'flex-end',
    color: '#8B54F7',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textDecoration: 'none',

    [theme.breakpoints.up(768)]: {
      lineHeight: '36px',
    },

    [theme.breakpoints.up(1024)]: {
      fontSize: 18,
    },

    '& > span': {
      fontSize: 10,
      color: 'inherit',
      marginLeft: 6,

      [theme.breakpoints.up(1024)]: {
        fontSize: 12,
      },
    },
  },
});
