import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { makeStyles, Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { toAbsoluteUrl } from '../../../utils/AssetsHelpers';

import { STATUS_DATA } from './statusData';
import styles from './styles';

const useStyles = makeStyles(styles);

const PaymentsListItem = ({ createdAt, amount, currency, state }) => {
  const { t } = useTranslation('common');

  const classes = useStyles();

  const currentStatuData = useMemo(
    () => STATUS_DATA[state] ?? STATUS_DATA.pending,
    [state],
  );

  return (
    <Box className={classes.listItem}>
      <Box className={classes.listItemRootIconContainer}>
        <Box
          component="img"
          src={toAbsoluteUrl('/assets/icons/pos-payment.svg')}
          alt="Pos Payment Icon"
          className={classes.listItemRootIconImg}
        />
      </Box>

      <Box className={classes.listItemContent}>
        <Box className={classes.listItemContentColumn}>
          <Typography className={classes.listItemContentLabel}>
            {t('payments.payment')}
          </Typography>

          <Typography className={classes.listItemContentDescription}>
            {moment(createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Box>

        <Box className={classes.listItemContentColumnAlignEnd}>
          <Typography className={classes.listItemContentLabelSmall}>
            {`${amount} ${currency}`}
          </Typography>

          <Box className={classes.listItemContentStatusContainer}>
            <Box
              component="img"
              src={currentStatuData.iconSrc}
              alt="Status Icon"
              className={classes.listItemContentStatusIcon}
            />

            <Typography
              className={classes.listItemContentStatusText}
              style={{ color: currentStatuData.color }}
            >
              {t(currentStatuData.translationKey)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PaymentsListItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

export default PaymentsListItem;
