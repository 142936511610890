import { PATHS } from './paths';
import { isDevOrStagging, toAbsoluteUrl } from '../utils';

export const MENU_OPTIONS = [
  // {
  //   path: PATHS.users.path,
  //   label: PATHS.users.title,
  //   image: toAbsoluteUrl('/assets/images/users.svg'),
  // },
  {
    path: PATHS.wallet.path,
    label: PATHS.wallet.title,
    image: toAbsoluteUrl('/assets/images/wallet.png'),
  },
  {
    path: PATHS.myStore.path,
    label: PATHS.myStore.title,
    image: toAbsoluteUrl('/assets/images/store.svg'),
  },
  {
    path: PATHS.cashIn.path,
    label: PATHS.cashIn.title,
    image: toAbsoluteUrl('/assets/images/cash-in.svg'),
  },
  {
    path: PATHS.cashOut.path,
    label: PATHS.cashOut.title,
    image: toAbsoluteUrl('/assets/images/cash-out.svg'),
  },
  {
    path: PATHS.payments.path,
    label: PATHS.payments.title,
    image: toAbsoluteUrl('/assets/images/payments.svg'),
  },
  {
    path: PATHS.settings.path,
    label: PATHS.settings.title,
    image: toAbsoluteUrl('/assets/images/settings.svg'),
  },
  {
    path: PATHS.profile.path,
    label: PATHS.profile.title,
    image: toAbsoluteUrl('/assets/images/users.svg'),
  },
  {
    path: PATHS.reports.path,
    label: PATHS.reports.title,
    image: toAbsoluteUrl('/assets/images/reports.svg'),
  },
];

export const MENU_OPTIONS_2 = [
  {
    key: 'wallet',
    image: toAbsoluteUrl('/assets/icons/wallet-white.svg'),
    path: PATHS.wallet.path,
  },
  {
    key: 'store',
    image: toAbsoluteUrl('/assets/icons/store-white.svg'),
    subOptions: [
      {
        key: 'companyInfo',
        path: PATHS.myStore.path,
        isExact: true,
      },
      {
        key: 'currenciesTokens',
        path: PATHS.myStoreTokens.path,
        isExact: true,
      },
      {
        key: 'locals',
        path: PATHS.myStoreLocales.path,
        isExact: false,
      },
    ],
  },
  {
    key: 'integrations',
    image: toAbsoluteUrl('/assets/icons/integrations-white.svg'),
    subOptions: [
      {
        key: 'cashIn',
        path: PATHS.cashIn.path,
      },
      {
        key: 'cashOut',
        path: PATHS.cashOut.path,
      },
      // {
      //   key: 'swap',
      //   path: '/',
      // },
    ],
  },
  {
    key: 'payments',
    image: toAbsoluteUrl('/assets/icons/payments-white.svg'),
    path: PATHS.payments.path,
  },
  {
    key: 'settings',
    image: toAbsoluteUrl('/assets/icons/settings-white.svg'),
    path: PATHS.settings.path,
  },
  {
    key: 'profile',
    image: toAbsoluteUrl('/assets/icons/profile-white.svg'),
    path: PATHS.profile.path,
  },
  {
    key: 'reports',
    image: toAbsoluteUrl('/assets/icons/reports-white.svg'),
    path: PATHS.reports.path,
  },
];

export const OTHERS_KM_PAGES = [
  {
    url: 'https://marketplace.kriptonmarket.com/',
    title: 'marketplace',
    image: toAbsoluteUrl('/assets/icons/ic_store.svg'),
  },
  {
    url: isDevOrStagging ? 'https://staging.kripton.la' : 'https://kripton.la',
    title: 'kriptoservices',
    image: toAbsoluteUrl('/assets/icons/ic_globalization.svg'),
  },
];
