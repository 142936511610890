import API from '../config/api';

const ENPOINTS = {
  LOGIN: '/public/user/login',
  REGISTER: '/auth/register',
  REQUEST_PASSWORD: '/auth/forgot-password',
  ME: '/internal/v1/merchants/me',
  REGISTRY: '/internal/v1/merchants',
  RECOVERY: '/public/user/recovery_password',
  CHANGE_PASSWORD: '/internal/v1/merchants/recovery_password/set',
};

export function login({ email, password, from = 'cash', store }) {
  return API.post(ENPOINTS.LOGIN, {
    username: email,
    password,
    from,
    store,
  });
}

export function register(email, fullname, username, password) {
  return API.post(ENPOINTS.REGISTER, {
    email,
    fullname,
    username,
    password,
  });
}

export function requestPassword(email) {
  return API.post(ENPOINTS.REQUEST_PASSWORD, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return API.get(ENPOINTS.ME);
}

export const saveUser = (params) => {
  const { username, password } = params;

  return API.post(ENPOINTS.REGISTRY, {
    username,
    password,
  });
};

export const sendEmailRecovery = ({ username }) =>
  API.post(ENPOINTS.RECOVERY, { email: username });

export const setNewPassword = ({ password, token }) =>
  API.post(ENPOINTS.CHANGE_PASSWORD, {
    new_password: password,
    token,
  });
