import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';

import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const StorePresentation = ({
  loading,
  data: { logo, name, reference },
  state,
  classes: classesProp,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const isNotChecking = useMemo(() => state !== 'checking', [state]);

  const logoUrl = useMemo(() => {
    if (!logo) return undefined;

    return isNotChecking ? logo : toAbsoluteUrl('/assets/icons/ic_search.svg');
  }, [isNotChecking, logo]);

  return (
    <CardWithProgressLoading
      loading={loading}
      className={cn(classes.card, classesProp.card)}
      progressSize={65}
      fullHeight={false}
    >
      <Box className={cn(classes.cardHeader, classesProp.cardHeader)}>
        <Box
          className={cn(classes.cardAvatar, classesProp.cardAvatar, {
            [classes.cardAvatarFallback]: !logoUrl,
            [classesProp.cardAvatarFallback]: !logoUrl,
          })}
        >
          {logoUrl && (
            <Box
              component="img"
              src={logoUrl}
              alt="caritas"
              className={cn(classes.cardAvatarImg, classesProp.cardAvatarImg)}
            />
          )}

          {!logoUrl && (
            <>
              <CameraAltOutlinedIcon
                color="inherit"
                className={classesProp.cardAvatarEmptyIcon}
              />

              <Typography
                color="inherit"
                variant="caption"
                className={classesProp.cardAvatarEmptyText}
              >
                {t(`store.logoOrPhoto`)}
              </Typography>
            </>
          )}
        </Box>

        <Box className={cn(classes.cardHeaderInfo, classesProp.cardHeaderInfo)}>
          <Typography
            className={cn(classes.cardTitleText, classesProp.cardTitleText)}
          >
            {name}
          </Typography>

          <Box
            className={cn(
              classes.cardHeaderInfoDescription,
              classesProp.cardHeaderInfoDescription,
            )}
          >
            <Typography
              className={cn(
                classes.cardSubtitleText,
                classesProp.cardSubtitleText,
              )}
            >
              {reference}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardWithProgressLoading>
  );
};

StorePresentation.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
  }),
  state: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

StorePresentation.defaultProps = {
  loading: true,
  data: {
    logo: null,
    name: '',
    email: '',
    reference: '',
  },
  state: '',
  classes: {},
};

export default StorePresentation;
