export default (theme) => ({
  root: {
    display: 'block',
    position: 'relative',

    [theme.breakpoints.up(769)]: {
      display: 'none',
    },
  },
  rootBtn: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'center',
    minHeight: 19,
    minWidth: 19,
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    zIndex: 1,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },

    '& $rootBtnLines, & $rootBtnLines::before, & $rootBtnLines::after': {
      backgroundColor: '#8B54F7',
      borderRadius: 7,
      height: 4,
      transition: 'all .5s ease-in-out',
      width: 19,
    },

    '& $rootBtnLines::before, & $rootBtnLines::after': {
      content: '""',
      position: 'absolute',
    },

    '& $rootBtnLines::before': {
      transform: 'translateY(-.4rem)',
    },

    '& $rootBtnLines::after': {
      transform: 'translateY(.4rem)',
    },

    [theme.breakpoints.up(536)]: {
      minHeight: 25,
      minWidth: 25,

      '& $rootBtnLines, & $rootBtnLines::before, & $rootBtnLines::after': {
        borderRadius: 7,
        height: 5.4,
        width: 25,
      },

      '& $rootBtnLines::before': {
        transform: 'translateY(-.5rem)',
      },

      '& $rootBtnLines::after': {
        transform: 'translateY(.5rem)',
      },
    },
  },
  rootBtnLines: {},
  navBar: {
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    inset: 0,
    opacity: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 999999,
  },
  navBarHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    position: 'relative',
  },
  mainLogo: {
    width: 36,
  },
  closeBtn: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 18,
    fontWeight: 700,
    height: 35,
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
    textAlign: 'left',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'all .5s ease-in-out',
    width: 35,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },

    '&:hover, &:active': {
      backgroundColor: '#1D1B2014',
    },
  },
  navBarContent: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  tabs: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    WebkitTapHighlightColor: 'transparent',
  },
  tabList: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #CAC4D0',
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'fixed',
    width: '100%',
  },
  tab: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: 0,
    display: 'flex',
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
    justifyContent: 'center',
    padding: 12,
  },
  tabSelected: {
    borderBottom: '2px solid #8B54F7',
    color: '#8B54F7',
  },
  tabPanel: {
    display: 'none',
  },
  tabPanelSelected: {
    display: 'flex',
    marginTop: 47,
    padding: 20,
  },
  tabPanelList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 400,
    gap: 3,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  tabPanelListItem: {
    borderBottom: '1px solid #CAC4D0',
    display: 'flex',
    padding: '6px 0',
  },
  tabPanelListItemNoBorderLastItem: {
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  collapseRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  collapseOpen: {},
  collpaseBtn: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 14,
    color: theme.palette.common.black,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    gap: 6,
    justifyContent: 'space-between',
    padding: '8px 12px',

    '&:hover': {
      backgroundColor: '#1D1B2014',
      color: theme.palette.common.black,
    },

    '&:active': {
      backgroundColor: '#8B54F7',
      color: theme.palette.common.white,
    },

    '&$collapseOpen:not(:active) $collapseBtnArrow': {
      color: '#8B54F7',
      transform: 'rotate(90deg)',
    },
  },
  collapseBtnArrow: {
    transition: 'all .5s',
  },
  collapseContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    marginTop: 0,
    overflow: 'hidden',
    paddingLeft: 24,
    transition: 'height .5s ease, margin .5s ease',
  },
});
